import { AgGridReact } from "ag-grid-react";
import "../../../node_modules/ag-grid-community/styles/ag-grid.css";
import "../../../node_modules/ag-grid-community/styles/ag-theme-alpine.css";
import { collectionsGridProps } from "../data-types";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import Button from "react-bootstrap/Button";

function openCollection(collection: string){
  window.alert("You pressed the button for the collection named: " + collection);
}

function OpenCollectionButton(props: ICellRendererParams){
  return (
    <Button variant="success" onClick= {() => openCollection(props.data.name)}> 
      Open
    </Button>
  );
}

function CollectionsGrid({ userCollections, showWhen }: collectionsGridProps) {

  // Column Definitions: Defines the columns to be displayed.
  const columnDefs: ColDef[] = [
    {
      flex: 1,
      headerName: "Name",
      field: 'name',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1.5,
      headerName: 'Description',
      field: 'info',
      wrapText: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Created',
      field: 'created_at',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: 1,
      headerName: 'Updated',
      field: 'updated_at',
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
    {
      flex: .5,
      field: 'action',
      headerName: '',
      cellRenderer: OpenCollectionButton,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
      }),
    },
  ];

  if (showWhen && userCollections){
    console.log(userCollections)
    return (
        // wrapping container with theme & size
        <div
        className="ag-theme-alpine" // applying the Data Grid theme
        style={{
          height: 500,
          borderRadius: "10px"
        }} // the Data Grid will fill the size of the parent container
        >
          <AgGridReact
              rowData={userCollections}
              columnDefs={columnDefs}
              rowHeight={50}
              className="ag-theme-alpine"
              border-radius="10px"
          />
        </div>
    );
  } else {
    return (
      <div>
       <h1> No collections to display</h1>;
      </div>
    )
  }
}

export default CollectionsGrid;